import PropTypes from "prop-types";

export function EmbedGoogleMap({ caption = "", source, height }) {
  return (
    <div>
      <iframe
        src={source}
        width="100%"
        height={height}
        style={{ border: 0 }}
        allowFullScreen
        title="Google Map"
      />
      {/* Display caption if it exists */}
      {caption && <p className="mt-3 text-xs text-right">{caption}</p>}
    </div>
  );
}

EmbedGoogleMap.propTypes = {
  caption: PropTypes.string,
  source: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
